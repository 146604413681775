export const palette = {
  white: '#ffffff',
  black: '#1e1e1e',
  orange: '#f37100',
  lightGold: '#FFC27A',
  gold: '#FF9315',
  brown: '#46423D',
  browner: '#413327',
  lightGrey: '#d9d9d9',
  darkGrey: '#8e8e8e'
};
